@import url("../../web/fonts/fontawesome5/css/all.css");



/* Site specific settings */
html
{
  /* Colors */
  --color-black:       #000000;
  --color-blue-dark:   #264DFF; /* old was #009BBC */
  --color-blue-light:  #99D7E4; /* share button */
  --color-green-dark:  #009A68;
  --color-green-light: #22D97C;
  --color-red:         #F80032;
  --color-white:       #FFFFFF;

  --formcontrols-themecolor: #000000;

  --color-theme-background: var(--color-blue-dark);
  --color-theme-actionlink: var(--color-blue-dark);

  /* Fonts */
  --font-nunito: 'Nunito Sans', sans-serif;
  --font-marker: 'Permanent Marker', cursive;

  --rtd-text-color:    var(--color-black);
  --rtd-heading-color: var(--color-black);
  --rtd-link-color:    var(--color-black);

  --rtd-heading-fontfamily: var(--font-nunito);
  --rtd-heading-fontweight: 900;

  --rtd-font-h1:   900 21px/21px 'Nunito Sans', sans-serif;
  --rtd-font-h2:   900 18px/24px 'Nunito Sans', sans-serif;
  --rtd-font-text:     16px/20px 'Nunito Sans', sans-serif;

  --slope: 8;
  --slope-vector: 4/-1;




  /************************************************************
   *
   *  Form controls
   */

  /* Normal height for single-line controls */
  --controls_height:                44px;
  --controls_radius:                 4px;
  --textcontrols_focus_bordercolor: #0067B4;
  --formcontrol-checkradio-label-color:    #000;


  --textcontrols-font:                 var(--rtd-font-text);
  --textcontrols_placeholdercolor:     var(--color-theme-light);
  --textcontrols_textcolor:            #282828;

  --textcontrols_disabled_bgcolor:     rgba(255,255,255,0.6);
  --textcontrols_disabled_bordercolor: #C5C5C5;
  --textcontrols_disabled_placeholder: inherit;
  --textcontrols_disabled_textcolor:   #282828;

  --textcontrols_padleft:         15px;
  --textcontrols_padright:        15px;

  --controls_textcolor_error:        #EE0000; /* lightest red which passes accessibility tests (4.53:1 contrast on white) */
  --controls_outline_color:          var(--color-black); /* var(--color-theme-light); */
  --controls_outline-color_disabled: #BFBFBF;
  --controls_outline-color_error:    #CD202C;
  --controls_outline-color_focus:    #222222;
  --controls_backgroundcolor_error:  #FFEAEA;

  --controls-icon-color: var(--color-blue-dark);



  /************************************************************
   *
   *  Form controls (checkbox and radio buttons)
   */

  --font-awesome:        "Font Awesome 5 Pro";
  --font-awesome-weight:  600;
  --font-awesome-size:    14px;
  --font-awesome-offsetx: 1px;
  --font-awesome-offsety: 1px;

  /* Checkboxes and radiobuttons */
  --formcontrol-checkradio-size:                24px;
  --formcontrol-checkradio-bordercolor:         var(--color-black);
  --formcontrol-checkradio-borderwidth:         3px;
  --formcontrol-checkradio-borderradius:        5px;
  --formcontrol-radio-innersize:                15px;

  --formcontrol-check-shift:                    3px;
  --formcontrol-radio-shift:                    0px;

/*
  --formcontrol-checkradio-checked-color:       #FFFFFF;
  --formcontrol-checkradio-checked-bgcolor:     var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-disabled-bgcolor:    #F2F2F2;
*/

  --formcontrol-checkradio-checked-color:       var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bgcolor:     #FFFFFF;
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-borderwidth: 3px;
  --formcontrol-checkradio-disabled-bgcolor:    #F2F2F2;
  --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);
}


/* mobile */
@media (max-width: 640px)
{
  html
  {
    --rtd-font-h1:   900 20px/20px 'Nunito Sans', sans-serif;
    --rtd-font-h2:   900 17px/23px 'Nunito Sans', sans-serif;
    --rtd-font-text:     14px/18px 'Nunito Sans', sans-serif;
  }
}


html
{
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  font-size: 100%;
  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  color: #000000;
}

body
{
  background-color: #FFFFFF; /* required for WCAG compliance */
  color: #000000; /* required for WCAG compliance */
}

  h1
, h2
, h3
, .style_as_h1
, .wh-form__grouptitle
, .widget__title
{
  font-family: var(--font-nunito);
  font-weight: bold;
  color: var(--rtd-heading-color, #000000);
}

/* KEEP IN SYNC WITH h2_styling mixin (/shared/config.scss)
   FIXME: ook bij dit webdesign?
*/
h2
, .widget__title
{
  font: 900 20px/20px var(--font-nunito);
  color: var(--rtd-heading-color, #000000);
  margin: 0;
}

  p.normal
, p.normal_small
, p.intro
, p.subtitle
, p.credits
, ol.ordered
, ul.unordered
{
  color: #000000;
  color: var(--rtd-text-color, #000000);
  margin: 0;
}

  p.normal a
, p.normal_small a
, p.intro a
, p.subtitle a
, p.credits a
, ol.ordered a
, ul.unordered a
{
  color: var(--rtd-link-color);
  text-decoration: underline;
}

/*
, .page__contentarea--formwebtool a
{
  color: var(--rtd-link-color);

  text-decoration: underline;
}
*/


  p.normal a:hover
, p.normal_small a:hover
, p.credits a:hover
, ul.unordered a:hover
, ol.ordered a:hover
, h2.heading2 a:hover
, h3.heading3 a:hover
{
  text-decoration: underline;
}



@media (min-width: 376px)
{
  h1
  {
    font: 900 21px/21px 'Nunito Sans', sans-serif;
  }

  h2
  {
    font: 900 18px/24px 'Nunito Sans', sans-serif;
  }

    p.normal
  , ol.ordered
  , ul.unordered
  , .widget-linkslist
  {
    font: 16px/20px 'Nunito Sans', sans-serif;
  }
}



/* Mobile */
@media (max-width: 375px)
{
  h1
  {
    font: 900 20px/20px 'Nunito Sans', sans-serif;
  }

  h2
  {
    font: 900 17px/23px 'Nunito Sans', sans-serif;
  }

    p.normal
  , ol.ordered
  , ul.unordered
  {
    font: 14px/18px 'Nunito Sans', sans-serif;
  }
}

h1
{
  font: var(--rtd-font-h1);
}

h2
{
  font: var(--rtd-font-h2);
}

  p.normal
, ol.ordered
, ul.unordered
{
  font: var(--rtd-font-text);
}

a
{
  background-color: transparent; /* needed to comply to WCAG ? */
  /*color: #56145F;*/
}

b
{
  font-weight: 600; /* use semi-bold instead of bold */
}


sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;

  text-align: left;
}

  ul.unordered > li
, ol.ordered > li
{
  margin-top: 4px;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}





/* RTD vertical whitespace ***********************************************************/

.widget
{
  margin-top: 30px;
}

h2.heading2
{
  margin-top: 20px;
}

h3.heading3
{
  margin-top: 15px;
}

  .wh-video
, p.normal
, ul.unordered
, ol.ordered
{
  /* when changing this value also change $paragraph_space_between in config.scss */
  margin-top: 15px; /* $rtdcontent_vspace */
}



  .widget-button
, h2.heading2 + .widget
, h3.heading3 + .widget
, p.credits
, p.subtitle
{
  margin-top: 15px;
}

  h2.heading2 + .wh-video
, h2.heading2 + p.intro
, h2.heading2 + p.normal
, h2.heading2 + ul.unordered
, h2.heading2 + ol.ordered
, h3.heading3 + .wh-video
, h3.heading3 + p.intro
, h3.heading3 + p.normal
, h3.heading3 + ul.unordered
, h3.heading3 + ol.ordered
{
  margin-top: 10px;
}

ul
{
  margin: 0;
  padding: 0 0 0 15px;
}

ul li::marker
{
  /*color: #F80032;*/
  /*color: var(--color-theme-background);*/
  color: var(--color-green-dark);
  font-size: 16px;
}



/* Mobile whitespace */
@media (max-width: 640px)
{
  h1
  {
    margin-bottom: 15px;
  }

  .widget
  {
    margin-top: 15px; /* instead of 50px */
  }

  h2.heading2
  {
    margin-top: 15px; /* instead of 50px */
  }

  h3.heading3
  {
    margin-top: 15px; /* instead of 35px */
  }

    .wh-video
  , p.normal
  {
    /* when changing this value also change $paragraph_space_between in config.scss */
    margin-top: 15px; /* instead of 30px */
  }

    .widget-button
  , h2.heading2 + .widget
  , h3.heading3 + .widget
  {
    margin-top: 15px;
  }

    h2.heading2 + p.normal
  , h3.heading3 + p.normal
  {
    margin-top: 10px;
  }

    ul.unordered
  , ol.ordered
  {
    margin-top: 0;
  }
}



/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}
  .wh-rtd > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}

.page__contentarea--rtddoc > *:first-child
{
  margin-top: 30px;
}
